<template>
    <ion-app>
        <ion-router-outlet></ion-router-outlet>
        <!-- <ion-alert
            :is-open="updateExists"
            :header="$t('update.header')"
            :message="$t('update.message')"
            :buttons="updateAlertButtons"
            ></ion-alert> -->
    </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonAlert } from '@ionic/vue';
import { attachOfflineSyncListener } from '@/http/offlineSync';
// import updateMixin from '@/mixins/update';

export default {
    name: 'App',
    components: { IonApp, IonRouterOutlet, IonAlert },
    // mixins: [updateMixin],
    setup() {
        attachOfflineSyncListener();
    }
}
</script>

<style lang="scss">
@import '@/theme/global.scss';
</style>