<template>
    <master-layout :title="pageTitle" :back-button="true">
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
        </ion-tabs>
    </master-layout>
</template>

<script>
import { IonTabs, IonRouterOutlet } from '@ionic/vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
    name: 'TabsPage',
    components: { IonTabs, IonRouterOutlet },
    setup() {
        const { t } = useI18n();
        const route = useRoute();

        const pageTitle = computed(() => ('id' in route.params && route.params.id) ? t('form.title_edit') : t('form.title_add'));

        return {
            pageTitle
        }
    }
}
</script>