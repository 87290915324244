import countries from '@/data/countries.json';
import { isPlatform } from '@ionic/vue';

// Vrati popis svih država.
export const getCountries = () => {
    let countryArray = [];

    for(const [code, name] of Object.entries(countries)) {
        countryArray.push({ code, name });
    }

    return countryArray;
}

// Pronađi ime države po kodu.
export const getCountryName = (value) => {
    return value in countries ? countries[value] : '';
}

// Pronađi imena država po kodu.
export const getCountryNames = (value) => {
    let names = [];

    value.map(v => {
        if(v in countries) {
            names.push(countries[v]);
        }
    });

    return names.join(', ');
}

export const fixCountryCode = (value) => {
    const fixed = {
        'HRV': 'CRO',
        'SVN': 'SLO',
        'BLG': 'BUL',
        'XXK': 'KOS'
    };

    return value in fixed ? fixed[value] : value;
}

// Sortiranje liste po imenu ili datumu.
export const nameAndDateSorter = (params) => {
    return (a, b) => {
        if(params.orderColumn == 'name') {
            if(params.orderType == 'asc') {
                return b.name.localeCompare(a.name);
            } else {
                return a.name.localeCompare(b.name);
            }
        } else if(params.orderColumn == 'date') {
            if(params.orderType == 'asc') {
                return new Date(a.date) - new Date(b.date);
            } else {
                return new Date(b.date) - new Date(a.date);
            }
        }
    
        return 0;
    }
}

// Provjeri je li app otvoren sa desktopa/laptopa/tableta.
export const isDesktop = () => {
    return (isPlatform('desktop') || isPlatform('tablet')) && (!isPlatform('mobile') || isPlatform('ipad'));
}

// Podijeli listu u više dijelova neke veličine.
const splitIntoChunks = (arr, size) => {
    let split = [];

    for(let i = 0; i < arr.length; i += size) {
        split.push(arr.slice(i, i + size));
    }

    return split;
}

// Spoji neki broj dijelova liste u jednu listu.
const combineChunks = (arr, amount) => {
    let combine = [];

    arr.slice(0, amount + 1).map(a => combine = combine.concat(a));

    return combine;
}

export const prepareForInfiniteScroll = (arr, page, perPage) => {
    return combineChunks(splitIntoChunks(arr, perPage), page);
}

export const shuffleArray = (array) => {
    for(let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export const convertExcelDateToString = (excelDateValue) => {
    const baseDate = new Date(Date.UTC(1899, 11, 30));
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const offsetMilliseconds = excelDateValue * millisecondsPerDay;
    const dateObject = new Date(baseDate.getTime() + offsetMilliseconds);
 
    const formattedDate = dateObject.toISOString().split('T')[0]; // format and remove time

    return formattedDate;
}