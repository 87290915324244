import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import { Storage } from '@capacitor/storage';
import App from './App';
import router from './router';
import i18n from './i18n';

import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import './theme/variables.css';

import MasterLayout from '@/components/MasterLayout';
import './registerServiceWorker';

const main = async () => {
    const { value: locale } = await Storage.get({ key: 'language' });

    if(locale) {
        i18n.global.locale.value = locale;
    }

    const app = createApp(App)
        .use(IonicVue)
        .use(router)
        .use(i18n)
        .component('master-layout', MasterLayout);

    await router.isReady();

    app.mount('#app');
}

main();