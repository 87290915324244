import { createStore } from 'vuex';
import AuthModule from './modules/auth';
import CompetitionsModule from './modules/competitions';
import SettingsModule from './modules/settings';
 
const store = createStore({
    modules: {
        auth: AuthModule,
        competitions: CompetitionsModule,
        settings: SettingsModule
    }
});
 
export default store;