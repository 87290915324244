<template>
    <ion-button @click="openPicker">
        <img :src="require(`@/assets/img/${locale}.svg`)" :alt="locale.toUpperCase()">
    </ion-button>
</template>

<script>
import { IonButton, alertController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { Storage } from '@capacitor/storage';

export default {
    name: 'LoginButton',
    components: { IonButton },
    props: ['backButton', 'title'],
    setup() {
        const { t, availableLocales, locale } = useI18n({ useScope: 'global' });

        const openPicker = async () => {
            let inputs = availableLocales.map((lang) => {
                return {
                    type: 'radio',
                    label: t(`languages.${lang}`),
                    value: lang,
                    checked: lang == locale.value
                }
            });

            const alert = await alertController.create({
                header: t('language'),
                buttons: [t('continue')],
                inputs,
            });

            await alert.present();

            const { data } = await alert.onWillDismiss();

            if(data?.values) {
                locale.value = data.values;
                Storage.set({ key: 'language', value: data.values });
            }
        }

        return {
            locale,
            openPicker
        }
    }
}
</script>

<style lang="scss" scoped>
img {
    width: 28px;
}
</style>