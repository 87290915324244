import { Storage } from '@capacitor/storage';
import { getSettings } from '@/http/settings';
import { isOnline, offlineToast } from '@/helpers/network';

export default ({
    namespaced: true,
    state: {
        loaded: false,
        settings: [],
    },
    mutations: {
        SET_SETTINGS(state, settings) {
            state.settings = settings;
            state.loaded = true;
        }
    },
    getters: {
        getSetting: (state) => (sectionKey) => {
            const [section, key] = sectionKey.split('.');
            const setting = state.settings.find(s => s.key === key && s.section === section);
            return setting ? setting.value : null;
        },
        getSettings: (state) => (section) => {
            return state.settings.filter(s => s.section === section);
        }
    },
    actions: {
        async load({ commit }, force = false) {
            if(!force && this.state.settings.loaded) return;

            if(!await isOnline()) {
                const { value } = await Storage.get({ key: 'settings' });
                if(value) {
                    commit('SET_SETTINGS', JSON.parse(value));
                } else {
                    offlineToast('offline_settings');
                }

                return;
            }

            const settings = await getSettings();

            if(!settings || settings.length === 0) {
                offlineToast('offline_settings');
                return;
            }

            Storage.set({ key: 'settings', value: JSON.stringify(settings) });
            commit('SET_SETTINGS', settings);
        }
    }
});