import { Network } from '@capacitor/network';
import { toastController } from '@ionic/vue';
import i18n from '@/i18n';

// Provjeri ima li internetske veze.
export const isOnline = async () => (await Network.getStatus()).connected;

// Pokaži poruku da nema internetske veze.
export const offlineToast = async (message = 'offline_outdated_data') => {
    const { t } = i18n.global;

    toastController.create({ message: t(message), duration: 2000 }).then(t => t.present());
}