<template>
    <ion-page mode="md">
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar v-if="!route.meta.hideTabBar && loggedIn" class="tab-bar" :slot="tabBarSlot">
                <ion-tab-button tab="competitions" @click="$router.push({ name: 'competitions' })" :layout="tabButtonLayout">
                    <ion-icon :icon="ribbonOutline"></ion-icon>
                    <ion-label>{{ t('menu.competitions') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button v-if="loggedIn && isDesktop" @click="$router.push({ name: 'competition-form' })" :layout="tabButtonLayout">
                    <ion-icon :icon="addOutline"></ion-icon>
                    <ion-label>{{ t('menu.add_competition') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button v-if="loggedIn && !isDesktop" tab="fake"></ion-tab-button>
                <ion-fab v-if="loggedIn && !isDesktop" vertical="end" horizontal="center">
                    <ion-fab-button @click="onFabClick">
                        <ion-icon :icon="addOutline"></ion-icon>
                    </ion-fab-button>
                </ion-fab>

                <ion-tab-button v-if="loggedIn && isSuperuser" tab="settings" href="/tabs/settings" :layout="tabButtonLayout">
                    <ion-icon :icon="settingsOutline"></ion-icon>
                    <ion-label>{{ t('menu.settings') }}</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<script>
import { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel, IonFab, IonFabButton } from '@ionic/vue';
import { ribbonOutline, personOutline, addOutline, peopleOutline, podiumOutline, trophyOutline, settingsOutline } from 'ionicons/icons';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { isDesktop } from '@/helpers/misc';
import store from '@/store';

export default {
    name: 'TabsPage',
    components: { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel, IonFab, IonFabButton },
    setup() {
        const route = useRoute();
        const { t } = useI18n();

        const loggedIn = computed(() => store.getters['auth/isLoggedIn']);
        const isSuperuser = computed(() => store.getters['auth/isSuperuser']);

        const tabBarSlot = isDesktop() ? 'top' : 'bottom';
        const tabButtonLayout = isDesktop() ? 'icon-start' : 'label-hide';

        return {
            route,
            isDesktop,
            tabBarSlot,
            tabButtonLayout,
            loggedIn,
            isSuperuser,
            ribbonOutline,
            personOutline,
            addOutline,
            peopleOutline,
            podiumOutline,
            trophyOutline,
            settingsOutline,
            t
        }
    }
}
</script>

<style lang="scss" scoped>
ion-tab-bar {
    --background: var(--ion-color-light);
    --color: var(--ion-color-dark);

    ion-tab-button {
        &.tab-selected {
            color: var(--ion-color-primary);
        }
    }
}

ion-fab {
    margin-inline-start: -20px;

    ion-fab-button {
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
}
</style>