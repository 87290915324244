import http from '@/http';

export const login = async (payload) => {
    return await http.post('login', payload);
}

export const forgotPassword = async (payload) => {
    return await http.post('forgot-password', payload);
}

export const resetPassword = async (payload) => {
    return await http.post('reset-password', payload);
}